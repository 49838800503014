import React, { useEffect, useState } from "react";
import { TobBar } from "./components/top-bar";
import { Box, CircularProgress, Stack } from "@mui/material";
import BookingForm from "./components/booking-form";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFields, schema } from "./constants";
import KAFDImage from "src/assets/kafd.png";
import BookedSuccessfully from "./components/booked-successfully";
import PaymentFailed from "./components/payment-failed";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetKAFDBooking } from "src/api/kafd-prebooking";

const KAFDPreBookingPage: React.FC = () => {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const bookingId = searchParams.get("bookingId");
  const [bookingStatus, setBookingStatus] = useState<
    "init" | "paid" | "failedPayment"
  >("init");

  // APIS
  const {
    status,
    isFetching,
    data: bookingData,
  } = useGetKAFDBooking(bookingId as string);

  // React hook form
  const methods = useForm<FormFields>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      location: "",
    },
  });

  const { trigger, getValues, watch, setValue } = methods;

  useEffect(() => {
    if (status === "success") {
      setBookingStatus("paid");
    } else if (status === "error") {
      setBookingStatus("failedPayment");
    }
  }, [status]);

  console.log({ isFetching, bookingData });

  return (
    <Box
      display="flex"
      justifyContent="center"
      position={"relative"}
      px={{ xs: 2, sm: 4 }}
      pb={6}
    >
      <Box position={"absolute"} top={"0"} zIndex={0}>
        <img
          src={KAFDImage}
          // height={408}
          width={"100%"}
          // style={{ objectFit: "cover" }}
          alt=""
        />
      </Box>
      <Box width={"100%"} maxWidth={"1340px"} zIndex={3}>
        <TobBar />
        <Box>
          {!isFetching ? (
            <FormProvider {...methods}>
              {!bookingId ? (
                <>
                  <BookingForm />
                </>
              ) : (
                <>
                  {bookingStatus === "failedPayment" ? (
                    <PaymentFailed />
                  ) : (
                    <BookedSuccessfully bookingData={bookingData?.data} />
                  )}
                </>
              )}
            </FormProvider>
          ) : (
            <Box pt={"130px"} display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default KAFDPreBookingPage;
