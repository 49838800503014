import { createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

// Create an Emotion cache with the RTL plugin
export const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const createCustomTheme = (direction: "ltr" | "rtl" = "ltr") => {
  return createTheme({
    direction,
    typography: {
      fontFamily: "Cairo, sans-serif",
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#D4AD53",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#313133",
      },
      tertiary: {
        main: "#0E0E0E",
      },
      text: {
        primary: "#F1F1F1",
        secondary: "#CC9C33",
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: "6px",
            fontWeight: 600,
            textTransform: "none",
          },
        },
        variants: [
          {
            props: { size: "large" }, // The button for large size
            style: {
              height: "50px",
              fontSize: "15px",
            },
          },
          {
            props: { size: "medium" },
            style: {
              height: "42px",
              fontSize: "15px",
            },
          },
          {
            props: { size: "small" },
            style: {
              height: "32px",
              fontSize: "14px",
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            display: "block",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: "#313133",
            color: "#D0D0D0",
          },
          icon: {
            color: "#D0D0D0", // Arrow color
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "&::-webkit-scrollbar": {
              width: "9px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F", // Match dark mode background
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D", // Match component styling
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59", // Highlight on hover
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59",
            },
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          paper: {
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59",
            },
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59",
            },
          },
        },
      },

      MuiList: {
        styleOverrides: {
          root: {
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FFFFFF1F",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#FFFFFF4D",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FFFFFF59",
            },
          },
        },
      },
    },
  });
};

export default createCustomTheme;
