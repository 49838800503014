import moment from "moment";
import * as Yup from "yup";

export const defaultSteps = [
  {
    id: "bookingForm",
    fields: ["time", "gate"],
  },
  {
    id: "requested",
    fields: [],
  },
  {
    id: "cancelled",
    fields: [],
  },
];

export interface FormFields extends Yup.InferType<typeof schema> {}

// validations
export const schema = Yup.object().shape({
  // booking form
  location: Yup.string().required("Required"),
  hostName: Yup.string()
    .required("Required")
    .max(250, "cannotExceed250Characters"),
  hostNumber: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "MobileNumberEnglishDigitsOnly") // Ensures only English digits
    .matches(/^5\d{8}$/, "MobileNumber9digitsStartWith5"),
  guestName: Yup.string()
    .required("Required")
    .max(250, "cannotExceed250Characters"),
  guestNumber: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "MobileNumberEnglishDigitsOnly") // Ensures only English digits
    .matches(/^5\d{8}$/, "MobileNumber9digitsStartWith5"),
  arrivingDate: Yup.date()
    .required("Required")
    .typeError("invalidFormat")
    .test(
      "is-today-or-later",
      "arrivingDateNotPast",
      (value) => value && moment(value).isSameOrAfter(moment().startOf("day"))
    ),
  arrivingTime: Yup.date()
    .required("Required")
    .test("is-future-time", "arrivingTimeNotPast", function (value) {
      const { arrivingDate } = this.parent;
      if (arrivingDate && moment(arrivingDate).isSame(moment(), "day")) {
        return value && moment(value).isAfter(moment());
      }
      return true; // valid if not today
    }),
  leavingTime: Yup.date()
    .required("Required")
    .test(
      "is-after-arriving-time",
      "leavingTimeAfterArrivingTime",
      function (value) {
        const { arrivingTime } = this.parent;
        // Only check if arrivingTime has a value
        if (arrivingTime) {
          return value && moment(value).isAfter(moment(arrivingTime));
        }
        return true; // Validation passes if arrivingTime is not set
      }
    ),
});
