import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { LocalStorageConstants } from "src/constants/_localStorage";
import { genericErrorHandler } from "src/utils/_genericErrorHandler";
import { getLocalStoredData } from "src/utils/_localStorage";

// Create an axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

// Axios interceptor to inject the Language to requests
apiClient.interceptors.request.use(
  async (request: InternalAxiosRequestConfig) => {
    request.headers["Accept-Language"] = getLocalStoredData(
      LocalStorageConstants.locale
    );

    return request;
  }
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    // Handle errors globally using the provided genericErrorHandler
    genericErrorHandler(error);
    return Promise.reject(error);
  }
);

export default apiClient;
